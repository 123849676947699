import './App.css';

function App() {
  return (
    <div className="App">
      <div className="MainPage-header">
        <div className="top_nav_1">苌弘青云欢迎您</div>
        <div className="top_nav_2">玉经磨琢多成器，剑拔沉埋更倚天</div>
        <div className="SubSite-Link-Group">
          <div className="SubSite-Link">
            <img className="SubSite_Logo" src="/images/Scratch_Logo.png" alt=""/>
            <a
              className="External-Link"
              href="/scratch/index.html"
              rel="noopener noreferrer">
              Scratch
            </a>
          </div>
          <div className="SubSite-Link">
            <img className="SubSite_Logo" src="/images/Python_Logo.png" alt=""/>
            <a
              className="External-Link"
              href="/python/index.html"
              rel="noopener noreferrer">
              Python
            </a>
          </div>
          <div className="SubSite-Link">
            <img className="SubSite_Logo" src="/images/Cplusplus_Logo.png" alt=""/>
            <a
              className="External-Link"
              href="/cplusplus/index.html"
              rel="noopener noreferrer">
              C/C++
            </a>
          </div>
          <div className="SubSite-Link">
            <img className="SubSite_Logo" src="/images/ArchiModel_Logo.png" alt=""/>
            <a
              className="External-Link"
              href="https://www.ArchiModel.top"
              target="_blank"
              rel="noopener noreferrer">
              ArchiModel
            </a>
          </div>
        </div>
        <div className="bottom_nav">咨询交流（自媒体：重龙散人，微信号：CHSR_A001、CHSY_A001，QQ：44502559）</div>
        <a
          className="Beian-link"
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
        >
          蜀ICP备17022048号-5
        </a>
      </div>
    </div>
  );
}

export default App;
